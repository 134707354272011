#timelogContent .employee-photo {
  max-height: 100px;
}
#timelogContent .btn-check {
  min-width: 300px;
  padding: 30px 22px;
}
#timelogContent .button-block {
  margin-top: 40px;
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
